import React from "react"
import Obfuscate from "react-obfuscate"
import { graphql } from "gatsby"
import {
  Section,
  LandingLogo,
  LandingVideo,
  ActionButton,
  LandingEvent,
  ImageButton,
  SectionTitle,
  SectionText,
  RichText,
  SponsorSlider,
  Card,
  SiteTitle,
  ObfuscatedLink,
  OutboundLink,
  Copyright,
  Link,
  MenuDrawer,
} from "../components"
import styled from "styled-components"
import theme from "../theme"
import { Typography, Button, Grid } from "@material-ui/core"
import { InterestField } from "../components/InterestField"

const CenteredButton = styled(Button)`
  align-text: center;
  margin: 10px;
  margin-top: ${theme.spacing(4)}px;
  z-index: 10;
`

const CenteredActionButton = styled(ActionButton)`
  display: table;
  margin: 0 auto;
  margin-top: ${theme.spacing(10)}px;
  margin-bottom: ${theme.spacing(8)}px;
  z-index: 10;
`
const InschrijvenButton = styled(CenteredActionButton)`
  font-size: 1.1rem;
`

// const ObfuscatedLinkWithoutUnderline = styled(ObfuscatedLink)`
//   text-decoration: none;
// `

export const IndexPage = ({ data }) => {
  const {
    introMode,
    logoButtonLabel,
    mainShowIntroprogramma,
    introTitle,
    intro,
    introButton,
    eventsTitle,
    eventsText,
    futureStudentTitle,
    futureStudentText,
    // sleepingPlaceTitle,
    // sleepingPlaceText,
    // futureStudentButton,
    sponsorsTitle,
    sponsorsText,
    sponsorsButtonLabel,
    contactTitle,
    contactText,
    contactButtonLabel,
    interestShowInterestField,
  } = data.allContentfulHomePage.edges[0].node

  const {
    buildingName,
    streetName,
    houseNumber,
    zipCode,
    city,
    phoneNumberBestuur,
    phoneNumberSocieteit,
    adressLink,
  } = data.allContentfulMetaInfo.nodes[0]

  return (
    <React.Fragment>
      <SiteTitle />
      <MenuDrawer startTransparent />

      {/* Landing */}
      <Section
        verticalSpacing={2}
        horizontalSpacing={10}
        slant="dark"
        flipped
        slantCenter
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <LandingLogo />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={4}>
            <LandingVideo />
          </Grid>
        </Grid>
        {introMode ? (
          <InschrijvenButton to="/inschrijven" component={Link}>
            {logoButtonLabel}
          </InschrijvenButton>
        ) : (
          ""
        )}
      </Section>

      {/* Introduction */}
      <Section
        color="dark"
        slant="background"
        slantCenter
        horizontalSpacing={3}
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6} xl={4}>
            <Section horizontalSpacing={3} verticalSpacing={0}>
              <SectionTitle>{introTitle}</SectionTitle>
              <RichText dark>{intro.json}</RichText>
            </Section>
            <Link to="/kernwaarden">
              <ImageButton
                imageUrl={introButton.image.file.url}
                title={introButton.title}
                subtitle={introButton.subtitle}
                chevron
              />
            </Link>
          </Grid>
        </Grid>
      </Section>

      {/* Newsletter */}
      <React.Fragment>
        {interestShowInterestField ? (
          <Section horizontalSpacing={3} verticalSpacing={2}>
            <InterestField />
          </Section>
        ) : (
          ""
        )}
      </React.Fragment>

      {/* Sleeping place */}
      {/* <React.Fragment>
        {introMode ? (
          <Section horizontalSpacing={3} slant="accent">
            <Grid container justify="center">
              <Grid item xs={12} sm={10} md={6} xl={4}>
                <Section horizontalSpacing={3} verticalSpacing={0}>
                  <SectionTitle>{sleepingPlaceTitle}</SectionTitle>
                  <SectionText>{sleepingPlaceText}</SectionText>
                </Section>
                <ObfuscatedLinkWithoutUnderline
                  email="slaapplek@demos.nl"
                  obfuscateChildren={false}
                >
                  <ImageButton
                    imageUrl={futureStudentButton.image.file.url}
                    title={futureStudentButton.title}
                    subtitle={futureStudentButton.subtitle}
                    chevron
                  />
                </ObfuscatedLinkWithoutUnderline>
              </Grid>
            </Grid>
          </Section>
        ) : (
          ""
        )}
      </React.Fragment> */}

      {/* Events */}
      <Section horizontalSpacing={3} slant="dark" slantCenter flipped>
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6} xl={4}>
            <Section horizontalSpacing={3} verticalSpacing={0}>
              <SectionTitle>{eventsTitle}</SectionTitle>
              <SectionText>{eventsText.eventsText}</SectionText>
            </Section>
            <LandingEvent />
          </Grid>
        </Grid>
      </Section>

      {/* Sponsors */}
      <Section
        horizontalSpacing={0}
        color="dark"
        slant="accent"
        flipped
        noPaddingTop
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6} xl={4}>
            <Section horizontalSpacing={6} verticalSpacing={0}>
              <SectionTitle>{sponsorsTitle}</SectionTitle>
              <SectionText>{sponsorsText}</SectionText>
            </Section>
          </Grid>
        </Grid>

        <SponsorSlider />

        <Grid container justify="center">
          <CenteredButton
            variant="contained"
            color="secondary"
            size="large"
            href="/zakelijk"
          >
            {sponsorsButtonLabel}
          </CenteredButton>
        </Grid>
      </Section>

      {/* Contact */}
      <Section noPaddingTop horizontalSpacing={3} color="accent">
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6} xl={4}>
            <Section horizontalSpacing={3} verticalSpacing={0}>
              <SectionTitle>{contactTitle}</SectionTitle>
              <SectionText>{contactText}</SectionText>
            </Section>
            <Card>
              <Typography variant="h6">Sociëteit</Typography>
              <Typography paragraph>{buildingName}</Typography>
              <Typography variant="h6">Adres</Typography>
              <Typography paragraph>
                <OutboundLink href={adressLink}>
                  {streetName} {houseNumber}
                  <br />
                  {zipCode} {city}
                </OutboundLink>
              </Typography>
              <Typography variant="h6">Telefoon</Typography>
              <Typography paragraph>
                Sociëteit: <ObfuscatedLink tel={phoneNumberSocieteit} />
                <br />
                Bestuur: <ObfuscatedLink tel={phoneNumberBestuur} />
              </Typography>
              <Typography variant="h6">E-mail</Typography>
              <Typography paragraph>
                <ObfuscatedLink email={"bestuur@demos.nl"} />
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <CenteredButton
            variant="contained"
            color="secondary"
            size="large"
            component={Obfuscate}
            email="bestuur@demos.nl"
            obfuscateChildren={false}
          >
            {contactButtonLabel}
          </CenteredButton>
        </Grid>
        <Copyright mt={4} />
      </Section>
    </React.Fragment>
  )
}

export const query = graphql`
  query getAllData {
    allContentfulHomePage {
      edges {
        node {
          introMode
          mainShowIntroprogramma
          logoButtonLabel
          introTitle
          intro {
            json
          }
          introButton {
            title
            subtitle
            image {
              file {
                url
                contentType
              }
            }
          }
          interestShowInterestField
          futureStudentTitle
          futureStudentText {
            json
          }
          sleepingPlaceTitle
          sleepingPlaceText
          futureStudentButton {
            title
            subtitle
            image {
              file {
                url
                contentType
              }
            }
          }
          eventsTitle
          eventsText {
            eventsText
          }
          sponsorsTitle
          sponsorsText
          sponsorsButtonLabel
          contactTitle
          contactText
          contactButtonLabel
        }
      }
    }
    allContentfulMetaInfo {
      nodes {
        buildingName
        streetName
        houseNumber
        zipCode
        city
        phoneNumberBestuur
        phoneNumberSocieteit
        adressLink
      }
    }
  }
`

export default IndexPage
