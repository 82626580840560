import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Section, ActionButton, SectionTitle, SectionText } from "../"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import { Typography, Grid, Card } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"
import CheckIcon from "@material-ui/icons/Check"

const ButtonGrid = styled(Grid)`
  margin-top: ${theme.spacing(3)}px;
  margin-bottom: 0px;
  text-align: center;
`

const SuccessCard = styled(Card)`
  margin-top: ${theme.spacing(3)}px;
  padding: 14px;
  padding-bottom: 10px;
`

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const InterestField = () => {
  const formName = "NieuwsbriefInschrijvingen"

  const {
    interestTitle,
    interestText,
    interestButtonText,
    interestSuccessText,
  } = useStaticQuery(graphql`
    query getInterestFieldData {
      allContentfulHomePage {
        edges {
          node {
            interestTitle
            interestText
            interestButtonText
            interestSuccessText
          }
        }
      }
    }
  `).allContentfulHomePage.edges[0].node

  const [formData, setFormData] = React.useState({ email: "" })

  const textFieldProps = (name) => ({
    disabled: loading,
    variant: "outlined",
    fullWidth: true,
    onChange: handleFormDataChange,
    value: formData[name],
    name,
  })

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleFormDataChange = (event) => {
    if (!event || !event.target) {
      return
    }

    event.persist()
    setFormData((oldFormData) => ({
      ...oldFormData,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = (event) => {
    setLoading(true)

    fetch("", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formData }),
    })
      .then(() => setSuccess(true))
      .catch(setError)
      .finally(() => setLoading(false))

    event.preventDefault()
  }

  return (
    <ValidatorForm
      name={formName}
      onSubmit={handleSubmit}
      method="post"
      onError={setError}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={10} md={6} xl={4}>
          <Section horizontalSpacing={3}>
            <SectionTitle>{interestTitle}</SectionTitle>
            <SectionText dark>{interestText}</SectionText>

            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value={formName} />
            <TextValidator
              {...textFieldProps("email")}
              validators={["required", "isEmail"]}
              errorMessages="Vul een correct E-Mail adres in"
              label="E-Mail"
            />
            <ButtonGrid item xs={12}>
              <ActionButton type="submit">{interestButtonText}</ActionButton>
            </ButtonGrid>
            <React.Fragment>
              {success ? (
                <SuccessCard>
                  <Grid container spacing={1}>
                    <Grid item>
                      <CheckIcon color="primary" />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>{interestSuccessText}</Typography>
                    </Grid>
                  </Grid>
                </SuccessCard>
              ) : (
                ""
              )}
            </React.Fragment>
          </Section>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}
